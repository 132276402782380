
.sms-container{
    display: grid;
  grid-template-columns: 18% 82%;
 font-family: lato;
 min-height:954px
    /* bottom:0; */
    /* border:1px solid black; */
}
.messages {
    /* border:1px solid red; */
    font-family: lato;
    /* padding:50px; */
    padding-top:57px;
    padding-left:104px;
}
.aside {
    padding-top: 57px;
    padding-left: 14px;
}
.sms {
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 0.5px;
    height: 20px;
   
}
.sms-form {
    /* text-align: center; */
    width:260px;
    /* border:1px solid red; */
    display: flex;
    flex-direction: column;
    /* height:250px; */
    justify-content: space-between;
}
.sms-desc {
    font-size: 16px;
    /* color: #101010; */
    line-height: 1.25;
    letter-spacing: 0.32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}
 .sms-labels {
    /* color: #101010; */
    /* line-height: 1.25; */
    letter-spacing: 0.32px;
    margin-bottom: 5px;
    font-weight: bold;
 }
 
.inputs {
    border:1px solid black;
    border-radius: 4px;
    /* padding:8px; */
    width:260px;
    height:40px;
    padding: 9px 0px 12px 10px;
}
.buttons {
    /* border:1px solid red; */
    /* margin-left:-6px; */
    /* width:100%; */
    display:flex;
    justify-content: space-between;
}

.btn {
    width: 100px;
    color:white;
    font-size: 14px;
    font-weight: bold;
    /* font-weight: bold; */
    margin-left: 5px;
    background-color: var(----primary-color);
}


.btns {
    color:white;
    border:none;
    background-color: var(----navmenu-background);
    border-radius: 5px;
    padding:5px;
    height:40px;
    width:122px;
    font-size: 14px;
    letter-spacing: 0.56px;
    font-weight: bold;
}

/* styling of hændelser/incident  page */
.incident-container {
    margin-top:50px ;
    margin-left:105px;
    margin-right:60px

}
.incident-header {
    /* border:1px solid red; */
    display:flex;
    justify-content: space-between;
}
.title {
    font-weight: 600;
    font-size: 25px;
    height:20px;
    width:124px;
}
.incident-name{
    width:303px;
    height:40px;
    border-radius: 4px;
    border:1px solid #8d8d8d;
    padding:5px;
    color:#adadad;
    padding:13px 9px 17px 12px
}
.addInciBtn{
    width:187px;
    height:40px;
    background-color: var(----navmenu-background);
    border-radius: 4px;
    color:white;
    border:none;
    font-weight: 500;
    font-size: 14px;
    box-sizing: border-box;
    margin-left: 10px;
    /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
}




@media screen and (max-width:1100px) and (min-width:900px) {
    .sms-container {
  grid-template-columns: 22% 78%;

    }
   
}

@media screen and (max-width:1400px) {
    .incident-container {
        margin-left:0;
        margin-right:0;
    }
}

@media screen and (max-width:900px){
    .messages {
        margin-left: 0;
        width:100%
    }
    .sms-container{
        width: 100%;
        min-height: auto;
        height:calc(100vh - 50px);
        
        display:flex;
    }
    .messages {
        padding:10px
    }
     .sms-form{
        /* width:100%; */
        /* padding:0px 20px; */
     }
}

