.perioder {
    /* border:1px solid red; */
    display:flex;
    justify-content: space-between;
    /* height:198px; */
}
.years-box {
    /* border:1px solid black; */
    padding: 31px 23px 22px 27px;
    border-radius: 4px;
    width: 473px;
    background-color: #f7f7f7;
}
.year-select {
    width: 186px;
  height: 40px;
  flex-grow: 0;
  margin: 0 10px 31px 11px;
  padding: 1px 12px 1px 15px;
  border-radius: 5px;
  border: solid 1px #adadad;
  background-color: #fff;
  background-size: 16px 19px;
}
.year-line {
    width:1px;
    position: absolute;
    top:1px;
    height:40px;
    right:62px;
    background-color: #dee0e3;
}

/* .buttons {
    margin-right: 10px;
    border-radius: 4px;
    height:40px;
    background: transparent;
    border: solid 1px #982f2f;
    color: #982f2f;
    font-size: 12px;
    font-weight: 800;
    box-sizing: border-box;
    
} */

.datoPerioder{
    width: 473px;
    /* border:1px solid red; */
    margin-left: 51%;
    /* background-color: #f7f7f7; */
    display: flex;
    /* justify-content: space-between; */
    /* justify-content: right; */
}

.periodeBtn{
    background-color: #982f2f;
    color: white;
    width: 91px;
  height: 40px;
  font-family: Lato;
  border:none;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 24px;
}
.cal {
    position: absolute;
  top: -10px;
  border:1px solid red;
  left: -180px;
  margin-top: 50px;
  letter-spacing: 0.3px;
  /* z-index: 100; */
  border: 1px solid lightgrey;
  width: 450px;
  border-radius: 4px;
  z-index: 999;
  /* background-color: white; */
  box-shadow: 0 13px 13px 0 rgb(0 0 0 / 12%);
}
.barsContainer {
    /* border:1px solid red; */
    /* display:grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr ; */
    margin-top: 40px;
    margin-bottom: 100px;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    display:flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.bars {
    border: solid 1px #dee0e3;
    height: 444px;
    padding: 24px 25px 41px 25px;
    width: 217px;
    margin-right:37px;
    border-radius: 2px;
    position: relative;
    top:0;
    background-color: #fff;
    margin-bottom: 50px;
    /* display:flex; */
    /* justify-content: space-between; */
}

.tal {
    transform: rotate(-270deg);
    font-family: Lato;
    font-size: 10px;
    position: absolute;
    top:55px;
    width:80px;
    /* border:1px solid red; */
    /* right:0; */
    left:-25px;
    letter-spacing: 0.5px;
}

.bar1 , .bar2{
    /* border:1px solid red; */
 /* display: flex; */
 /* justify-content: flex-end; */
 position: relative;
 text-align: center;
    width: 80px;
  height: 367px;
  background-color: #f7f7f7;
}
.barsDesc {
    display:none;
    /* border:1px solid red; */
    justify-content: space-between;
    /* text-align: center; */
  /* width: 217px; */

}
.antal {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    color: #46484c;
    width: 217px;

}
.billet {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    color: #46484c;
    width: 217px;
    padding-left:9px
}
.oms {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    color: #46484c;
    width: 150px;
    padding-left:12px

    /* margin-right: 20px; */
    /* border:1px solid red */
}
.barsTitle {
    /* margin: 14px; */
    margin-top: 10px;
    display:flex;
    justify-content: space-between;
    /* text-align: center; */
    font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  color: #46484c;
  width: 217px;
  padding:0 39px;
/* border:1px solid  */
}
.barsTitleAntal {
    margin-top: 10px;
    display:flex;
    justify-content: space-between;
    /* text-align: center; */
    font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  color: #46484c;
  width: 217px;
  padding:0 20px
}
.buttons-group {
    display:flex;
    justify-content: space-between;
}
.butto {
    background: transparent;
    margin-right: 10px;
    padding:5px 30px;
    border: solid 1px #982f2f;
    color: #982f2f;
    font-size: 12px;
    font-weight: 800;
    height:40px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 5px;
}
 .butto_7 {
  /* color:black; */
  /* font-size:20px; */
  position: absolute;
  top:132px;
  right:0;
}
.datesContainer{
  /* border:1px solid red; */
  position: relative;
  height:200px
}
.barsDate {
    font-size: 12px;
    color: #46484c;
    margin-top: 8px;
    margin-left: 15px;
}
.soldBar1Tal{
  position: absolute;
  bottom:310px;
  right:0;
  left:0;
  color:#a6b40a
  /* text-align: center; */
}
.soldBar2Tal{
    position: absolute;
    bottom:215px;
    right:0;
    left:0;
    color:#a6b40a

}
.soldBar1Procent, .billetBar1Procent, .billetBar2Procent, .kioskBar1Procent, .kioskBar2Procent , .omsBar2Procent, .omsBar1Procent, .soldBar2Procent {
  /* border-radius: 40px; */
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  color:white;
  width:55px;
  font-family: Lato;
  font-size: 12px;
  /* background-color: #46484c; */
  border: solid 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  
 right:0;
 left:0px;
 z-index: 9;

}
.thisYear{
  position: absolute;
  width:57px;
 right:0;
 margin:0 auto;
 left:0px;
 color:#46484c;
 /* color:white; */
 font-size:12px;
 z-index: 9;
 border-radius: 50px;
 border: solid 1px rgba(0, 0, 0, 0.1);
 background-color: white;;
}
/* .soldBar2Procent{
    position: absolute;
    bottom:190px;
    right:0;
    left:15px;
    z-index: 9;
    /* background-color: 
    #cf4747; */
    /* border-radius: 40px; */
    /* color:white; */
    /* width:50px; */
    /* font-family: Lato; */
    /* font-size: 12px; */
    /* border: solid 1px rgba(0, 0, 0, 0.1); */
/* } */ 

.soldBar1{
  opacity: 0.5;
  background-color: #dbe754;
  height:300px;
  text-align: end;
  position: absolute;
  left:0;
  right:0;
  bottom:0
}
.soldBar2{
    background-color: #dbe754;
    height:200px;
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0
  }
  .billetBar1{
    opacity: 0.5;
    background-color:  #a9e5ff;
    /* height:800px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0
    
  }
  .billetBar2{
    background-color:  #a9e5ff;
    /* height:300px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0
    
  }
  .billetBar1Tal {
    position: absolute;
    /* bottom:210px; */
    right:0;
    left:0;
    color:
    #4ea5c2
}
.billetBar2Tal {
    position: absolute;
    /* padding-bottom:5px; */
    /* border:1px solid red; */
    /* bottom:315px; */
    right:0;
    left:0;
    color:
    #4ea5c2
}

.billetBarProcent{
    position: absolute;
    /* bottom:290px; */
    /* padding-bottom:2px; */
/* height:18px; */
    right:0;
    left:15px;
    z-index: 9;
    background-color: #40b22d;
    border-radius: 40px;
    color:white;
    width:50px;
    font-family: Lato;
    font-size: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
}
  .kioskBar1 {
    opacity: 0.5;
   
    /* height:200px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #ff9b2f;
  }
  .andenOmsBar1 {
    opacity: 0.5;
    /* height:200px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #f5bd45;
  }
  .samletBar1 {
    opacity: 0.5;
    /* height:200px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #2c6b0f;
  }
  .kioskBar1Tal {
    position: absolute;
    /* bottom:0; */
    right:0;
    left:0;
    color:#d27817
}
.andenOmsBar1Tal {
  position: absolute;
  /* bottom:0; */
  right:0;
  left:0;
  color:
  #cb8d06
}
.samletBar1Tal {
  position: absolute;
  /* bottom:0; */
  right:0;
  left:0;
  color:
  #2a8102
}
  .kioskBar2 {
    /* height:300px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #ff9b2f;
  }
  .andenOmsBar2 {
    /* height:300px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #f5bd45;
  }
  .samletBar2{
    /* height:300px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #2a8102;
  }
  .kioskBar2Tal {
    position: absolute;
    /* bottom:0; */
    right:0;
    left:0;
    color:#d27817
}
.andenOmsBar2Tal {
  position: absolute;
  /* bottom:0; */
  right:0;
  left:0;
  color:#cb8d06
}
.samletBar2Tal {
  position: absolute;
  /* bottom:0; */
  right:0;
  left:0;
  color:#2a8102
}
.kioskBarProcent{
    position: absolute;
    bottom:290px;
    right:0;
    left:15px;
    z-index: 9;
    background-color: #40b22d;
    border-radius: 40px;
    color:white;
    width:50px;
    font-family: Lato;
    font-size: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
}
  .omsBar1 {
    opacity: 0.5;
    /* height:300px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #adb0ff;
  }
  .omsBar2 {
    /* height:180px; */
    text-align: end;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: 
    #adb0ff;
  }
  .omsBar1Tal { 
    position: absolute;
    /* bottom:310px; */
    right:0;
    left:0;
    color:
    #7174c7
  }
.omsBar2Tal {
    position: absolute;
    /* bottom:195px; */
    right:0;
    left:0;
    color:
    #7174c7
}
.omsBarProcent {
    position: absolute;
    bottom:170px;
    right:0;
    left:15px;
    z-index: 9;
    background-color: 
    #cf4747;
    border-radius: 40px;
    color:white;
    width:50px;
    font-family: Lato;
    font-size: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
}

input[type='radio']  {
    -webkit-appearance:none;
    width:12px;
    height:12px;
    border:1px solid var(----primary-color);
    border-radius:50%;
    outline:none;
   
}

input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
}

input[type='radio']:checked:before {
    background: var(----primary-color);
}

.multipleBarsContainer {
  /* border:1px solid red; */
}

.antalContainer {
  border-radius: 2px;
  border: solid 1px #dee0e3;
  background-color: #fff;
  padding:24px 22px 60px 22px;
  display:flex;
  /* flex-direction: column-reverse; */
  /* justify-content: space-between; */
}

.antalBar{
  width:80px;
  text-align: center;
  height:367px;
  background-color: #f7f7f7;
  position: relative;
  margin-right:5px;
}
.antalBarProcent{
  font-size: 12px;
  width: 55px;
  color:white;
  height: 20px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  /* background-color: #40b22d; */
  position: absolute;
  left:0;
  right:-30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index:9
  
}
.antalPeriode{
  font-size: 12px;
  width:69px;
  position: absolute;
  bottom:-40px;
  left:5px;
  display:flex;
  flex-direction: column;
  color:#46484c
}
.antalTotal{
  transform: rotate(-270deg);
  font-size: 10px;
  position: absolute;
  left:-50px;
  width:80px;
  top:10px;
  /* border:1px solid red; */
}
.antalTal{
  background-color: #dbe754;
  /* height:200px; */
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  /* top:30px */
}

.billetTal {
background-color: #a9e5ff;
position: absolute;
  bottom:0;
  left:0;
  right:0;
}
.kioskTal{
  background-color: 
  #ff9b2f;
position: absolute;
  bottom:0;
  left:0;
  right:0;
}
.kioskomsTal {
  background-color: 
  #adb0ff;
position: absolute;
  bottom:0;
  left:0;
  right:0;
}
.andenOmsTal {
  background-color: #f5bd45;
position: absolute;
  bottom:0;
  left:0;
  right:0;
}
.samletTal { background-color: 
  
#2c6b0f;
position: absolute;
  bottom:0;
  left:0;
  right:0;

}
.greenNr {
  color:#a6b40a;
  position: absolute;
  right:0;
  left:0;
}
.yellowNr {
  color:#cb8d06;
  position: absolute;
  right:0;
  left:0;
}
.darkGreenNr {
  color:#2a8102;
  position: absolute;
  right:0;
  left:0;
}
.blueNr {
  color:#4ea5c2;
  position: absolute;
  right:0;
  left:0;
}
.orangeNr {
  color:#d27817;
  position: absolute;
  right:0;
  left:0;
}
.purpleNr {
  color:#7174c7;
  position: absolute;
  right:0;
  left:0;
}
.dates-container{
  /* border:1px solid red; */
  width:80px;
  height:40px;
  display:flex;
  flex-direction: column;
}

/*progress bars style*/
.progressbarsContainer {
  border:1px solid #dee0e3;
  border-radius: 2px;
  /* width:960px; */
  /* padding:20px 61px 20px 20px; */
position: relative;

}
.progressBars{
  width:100%;
  height: 46px;
  background-color: #f7f7f7;
margin-bottom:2px;
display:flex;
position:relative;
}
.progressBars_1 {
  opacity:  0.95;
}
.progressBars_2 {
  opacity:  0.90;
}
.progressBars_3 {
  opacity:  0.85;
}
.progressBars_4 {
  opacity:  0.8;
  /* background-color: #dbe754; */

}
.progressBars_5 {
  opacity:  0.75;
}
.progressBars_6 {
  opacity:  0.7;
}
.progressBars_7 {
  opacity:  0.65;
}
.progressBars_8 {
  opacity:  0.6;
}
.progressBars_9 {
  opacity:  0.55;
}
.progressBars_10 {
  opacity:  0.5;
}
.progressBars_11 {
  opacity:  0.45;
}
.progressBars_12 {
  opacity:  0.4;
  /* background-color: #dbe754; */
}
.progressBars_13 {
  opacity:  0.35;
}
.progressBars_14 {
  opacity:  0.3;
}
.progressBars_15 {
  opacity:  0.25;
  /* background-color: #dbe754; */
}
.progressBars_16 {
  opacity:  0.2;
}
.progressBars_17 {
  opacity:  0.15;
}
.progressBars_18 {
  opacity:  0.1;
}
.progressBars_19 {
  opacity:  0.05;
}
.progressBars_20 {
  opacity:  0.00;
}
.progressBars_21 {
  opacity:  0.50;
}
.progressBars_22 {
  opacity:  0.50;
}

.progressTotal{
  position: absolute;
  right:60px;
  top:0px;
  font-size: 12px;
}
.progressData {
  /* background-color: #dbe754; */
  /* width:50%; */
  display:flex;
  height: 46px;
  padding:8px;
/* line-height: 46px; */
}
.progressDato {
  font-weight: 600;
  color: #101010;
  /* margin:10px; */
  position:absolute;
  top:10px;
  left:8px;
  
  /* border:1px solid red; */
  /* display:flex; */
  /* justify-content: space-between; */

}
.progressBars .thisYear {
  /* border:1px solid red; */
  position: absolute;
  top:12px;
  right:55%;
  /* left:20%; */
  width:45px;
  text-align: center;
  /* padding-left: 10px; */
}
.progressProcent{
  width:45px;
  height:20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 40px; */
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #40b22d;
  color:white;
  font-size:12px;
  position: absolute;
  left:20%;
  letter-spacing: 0.7px;
  top:26px;
  text-align: center;
  /* padding-left: 6px; */
  padding-top:0px;
  /* box-sizing: border-box; */
  /* position: absolute; */
  /* left:110px; */
  /* top:12px; */
  /* padding: 3px; */
}
.progressValue{
  /* color:#a6b40a; */
  /* position: absolute; */
  /* right:0%; */
  /* border:1px solid red; */
  width: 60px;
  text-align: end;
  /* padding-left:min(130px); */
  padding-top:10px;
}
.progressContainer{
  width:130px;
  border:1px solid red
}




  /* media query for responsive layout */
@media screen and (max-width: 1435px){
    .periodesam {
        margin-left:10px;
        margin-right:10px
    }
    
}



@media screen and (max-width: 1230px) {
  .datesContainer {
    height:200px;
    margin-bottom: 50px;
    
  }
 .butto_7{
  top:71%;
  right:50%
 }
  .progressbarsContainer {
    width: 99%;
  }
  .progressDato{
font-size: 12px;
  }
   .perioder{
    flex-direction: column;
   }
   .btns-container {
    /* margin-top: 220px; */
   }
   .buttons-group{
    flex-wrap: wrap;
    justify-content: stretch;
    
   }
   .buttons{
    margin-top: 5px;

   }
   .datoPerioder{
    margin-left: 0px;
    margin-top: 10px;
   }
   .years-box{
    margin-top: 20px;
   }
}
@media screen and (min-width:1450px) {
  .barsContainer {
    width:105%;
    /* //border:1px solid black */
  }
  
}
@media screen and  (min-width:1283px) {
 .extra {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1200px){
.barsContainer {
  padding-right:10px;
  /* flex-direction: column; */
  flex-wrap: wrap;
  
}
.between2and10 {
  /* border:1px solid red; */
  flex-direction: column;
}
.antalContainer{
justify-content: space-between;
}
}
@media screen and (max-width: 500px) {
  .datesContainer{
    margin-bottom: 200px;
  }
    .years-box,  .datoPerioder {
        width:90vw
    }
.datoPerioder{
    display:grid;
}
.cal {
    left:-19px;
    width:99vw;
}
.barsContainer {
    display:grid;
    /* flex-direction: column; */
    grid-template-columns: 1fr ;
 }
 .bars {
    margin-bottom: 50px;
 }
}
@media screen and (max-width:1200px) and (min-width:1100px) {
.butto_7 {
  right:40%;
  top:94%
}
}
@media screen and (max-width:1100px) and (min-width:900px){
  .butto_7 {
    right:28%;
  top:94%

  }
}
@media screen and (max-width: 900px)  and (min-width:760px){
 .butto_7{
 
  right:35%;
  top:94%

 }
}
@media screen and (max-width: 759px) and (min-width:630px){
.butto_7 {
  top:94%;
  right:20%
}
}
@media screen and (max-width: 629px)and (min-width:520px){
  .butto_7 {
    top:94%;
    right:5%
  }
}
@media screen and (max-width: 519px) and (min-width:500px) {
  .butto_7 {
    top:116%;
    right:2%
  }
}
@media screen and (max-width: 499px) {
  .butto_7 {
    top:180%;
   left: 0px;
   width:180px
  }
}
@media screen and (max-width: 396px) {
  .butto_7 {
    top:195%;
   left: 0px;
   width:180px
  }
}
@media screen and (max-width:1200px) and (min-width:900px) {
    .aside{
        padding-left:0;
        font-size: 13px;
    }
   
}

@media screen and (max-width: 1282px) and (min-width:500px){
     .barsContainer {
        display:grid;
        grid-template-columns: 1fr 1fr ;
     }
     .bars {
        margin-bottom: 100px;
     }
}