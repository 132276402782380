.btn {
    width: 100px;
    color:white;
    font-size: 14px;
    font-weight: bold;
    /* font-weight: bold; */
    margin-left: 5px;
    background-color: var(----primary-color);
}
.incidents-menu {
    /* border:1px solid red; */
    /* width:65%; */
    /* min-width:700px; */
    margin: auto;
    padding-left:5px;
    display:grid;
    grid-template-columns: 1fr  1fr;
  }
  .incidentLeftMenu{
      text-align: left;
      color:#8d8d8d;
      display:grid;
      grid-template-columns: 1fr 1fr;
  }
  .incidentRightMenu {
      display:grid;
      color:#8d8d8d;
      grid-template-columns:  1fr 0.6fr;
  }
  
  .incidents-container{
      /* border:1px solid lightgrey */
  }
  .box {
    /* display: flex; */
    /* justify-content: space-between; */
    position: relative;
    /* width:65%; */
    margin: auto;
    border:1px solid lightgrey;
    padding-top: 19px;
    padding-left:25px;
    margin-bottom: 5px;
    /* background-color: aqua; */
  }

.shown-menu {
    /* border:1px solid black; */
    display:grid;
    grid-template-columns: 1fr  1.4fr ;
    width:100%;
    margin:auto
}
.shown-left {
    text-align: left;
    display: grid;
    /* border:1px solid black; */
    grid-template-columns: 1fr 1fr;
}
.shown-right {
    display:grid;
    /* border:1px solid red; */
margin-left: 60px;
  grid-template-columns:  1fr 0.6fr;
}
.line {
    /* border-left:1px solid lightgrey; */
    margin-right:-100px
}
.addIncidentContainer {
    border:1px solid lightgrey;
    /* width:65%; */
    margin:auto;
    text-align: center;
    /* display:flex; */
    padding:10px;
}
.img-btn {
    /* border:1px solid red; */
    margin-top: 10px;
}
.besked-container {
    /* position: relative; */
}
.besked {
    border:1px solid #8d8d8d;
    padding:10px;
    width:550px;
    height:46px;
    border-radius: 4px;
}
.send-sms {
    background-color: transparent;
    border:1px solid var(----navmenu-background);
    color:var(----navmenu-background);
    padding:10px;
    font-weight: 700;
    border-radius: 4px;
    margin-left: 10px;
    /* margin-top: -10px; */
    font-size:14px;
    font-family: lato;
    position: absolute;
    /* top:10px; */
    height:46px;

}
.solved-checkbox {
    /* border:1px solid black; */
    /* margin-top: 15px; */
    /* width: 150px; */
    position: absolute;
    right:40px;
    height:20px;
    top:25px
}

.solved-checkbox input[type=checkbox] {
    /* padding:10px; */
    /* font-size: 110%; */
    transform: scale(1.5);
    margin-left: 10px;
    /* margin-top:5px; */
}

.sent-smsHeader {
    display:flex;
    justify-content: space-between;
    padding:15px;
    /* padding-top:10px; */
    box-sizing: border-box;
    width: 90%;
    height:56px;
    font-weight: 600;
}

.sent-sms {
    display:flex;
    justify-content: space-between;
    padding:15px;
    /* padding-top:10px; */
    box-sizing: border-box;
    width: 95%;
    height:56px;
}

.sent-sms:nth-child(odd) {
    background-color:  #f7f7f7;
} 

@media screen and (max-width:1200px) {
    .besked {
        width:300px
    }
    .solved-checkbox {
        right:20px
    }
    .box {
        padding-left: 10px;
    }
}

@media screen and (max-width:1030px) {
    .addInciBtn {
        /* color:red; */
        /* margin-top: -3px; */
    }
    .moreInciBtn {
        /* color:red; */

        margin-top: 10px;
    }
    .addIncidentContainer {
        margin-bottom: 10px;
        /* border:1px solid red */

    }

}

