:root {
  ----primary-color : #810101;
  ----user-color : #f1c895;
  ----line-color: #a83c3c;
  ----menu-background :#ececec;
  ----navmenu-background:#982f2f;
}

.App {
  /* text-align: center; */
  height:100%;
  /* height:auto; */
  min-height:90vh;
  max-width:1440px;
  margin:auto;
  
}
/* select {
  width: 300px;
  margin: 20px;
} */
/* option {
  min-width: 300px;
} */
select:focus {border:none}


.footer {
  text-align: center;
  
}

hr{
  color:darkgrey;
}

.containers {
 
height:100%;
 border:1px solid lightgrey
}

.hentBtn {
  background-color: var( ----navmenu-background);;
  color:white;
  padding:5px 20px;
  border:none;
  border-radius: 5px;
  margin-top: 2px;
}



@media screen and (max-width:900px){
  .App {
    min-height:auto;
  }
  .contain {
    width:100%;
    
  }
  .containers{
    border:none;
    display:grid;
    grid-template-rows: 50px 1fr;
    /* background-color: #982f; */
    /* margin-bottom: 60px; */
    /* height:50% */
    /* grid-template-rows: 50px 100% 50px; */
  }
}

@media screen and (max-width:1000px) {
  .App {
    width:100%
  }
  .containers {
    /* border:none */
  }
}


