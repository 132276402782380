.table-container {
  /* border:1px solid red */
}
.searchInput {
  width: 100%;
  outline: none;
  border:none;
  padding:10px;
  border:1px solid black;
  /* line-height: 2; */
}
.mobile{
  /* display:none; */
}
.table{
  color:inherit;
  font-size: 13px;
}

.table label span {
  display: none;
}
.table label {
  width: 50%;
  background-color: white;
  /* border-radius: 5px; */
}

.tr {
  text-decoration: underline;
  font-size: 15px;
  padding-top: 5px;
  height: 20px;
}

.rightAlign{
  text-align: right;
}

.th {
  cursor: pointer;
  /* text-decoration: underline; */
  font-weight: bold;
  /* font-size: large; */
}
.th:hover {
  background-color: lightgray;
}
.weeks {
  cursor:none !important;
 
}
.fils {
  border: 1px solid red;
  /* width: 150px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-classes {
    background-color: beige;
}

.trt {
  border:1px solid red;
}
.hide {
  /* border:1px solid red; */

  display:none;
  margin-top: -45px;
}

/* film dag styling */
.filmdag {
  /* border:1px solid red; */
  display:grid;
  border:1px solid white;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
}
.films{
  border-right: 1px solid white;
  /* padding:5px; */
/* border:1px solid white; */
}

.weekday{
  padding-top: 10px;
}

@media screen and (min-width:1450px) {
.mobile {
  display: none;
}
}

@media screen and (max-width:1450px){
  .desktop {
    display: none;
  }
 .mobile {
  /* display:flex; */
    width:100%;
   font-size: 13px;

  }
}

@media screen and (max-width:900px){
  .gg {
    /* margin-bottom: 500px; */
    background-color: transparent;
    /* border:1px solid grey */
    /* background-color: aqua; */
  }
  
}
/* 
@media screen and (max-width: 1439px) and (min-width: 901px) {
  .mobile {
    display: none;
    width:100%;
    font-size:5px
  }
  .desktop {
    display: none;
  }
} */

/* @media screen and (max-width:900px){
  .desktop {
    display: none;
  }
  .mobile {
    width:100%;
   font-size: 13px;

  }
} */