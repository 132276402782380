.reports-container {
    border:1px solid lightgrey;
    /* min-height: 90vh; */
    height:100%;
  /* height:99.8vh; */
  /* display: grid; */
  /* grid-template-rows: 70px auto; */
}

.reports{
    /* border:1px solid lightgrey; */
    display:grid;
    min-height: 954px;
    /* height:100%; */
    grid-template-columns: 18% 82%;
    font-family: lato;
    /* padding:10px; */
    /* min-height:100vh; */
    /* background-color: bisque; */
}
.side-menu{
    /* border:1px solid grey; */
    padding-top: 62px;
    padding-left: 23px;
    /* padding-bottom: 16px; */
    height:inherit;
    background-color: var(----menu-background);
}
.burger-menus {
  display:none
}
.menu {
    /* border:1px solid black; */
    /* padding:10px; */
    /* box-sizing: border-box; */
    padding: 6px 10px;
    border-radius: 5px;
    /* width:242px; */
    height: 42px;
    margin:4px;
    border:1px solid #e3e3e3;
    background-color: white;
    position: relative;
}

.menu-item {
    color: black;
    text-decoration: none;
}

.img-btn {
    background: transparent;
    position: absolute;
    top:5px;
    border:none;
    right:6px;
    transition: all 1s;
  }
  
  .rotated {
    padding-top:5px;
    transform: rotate(-180deg);
  }

  .side-submenu{
    /* border:1px solid red; */
    padding: 10px;
  }


  .main-menu {
    /* border:1px solid black;
    height:100%;
    width:100%; */
    /* padding:10px; */
    /* border:1px solid black; */
    /* padding-top:60px; */
    /* padding-left:100px; */
    /* padding-right: 10px; */
  }
  .heading {
    font-size: 22px;
  font-weight: 600;
  }
  Link:focus {
   color:black;
  }

.menu-title {
margin-left: 8px;
/* border:1px solid red; */
}

@media screen and (max-width:1200px) and (min-width:900px) {
  .reports{
grid-template-columns: 25% 75%;

  }
}

 

  @media screen and (max-width:900px){
    .reports-container {
      display:grid;
    grid-template-rows: 50px 1fr;

        /* grid-template-rows: 8vh 84vh 8vh; */
        border:none
    }
    .reports {
      width: 100%;
      display:flex;
      /* min-height: auto; */
      /* height:calc(100vh - 50px); */
      
    }
    .side-menu {
      display:none
    }
    .main {
      text-align: center;
    }
    .burger-menus {
      position: absolute;
      display:flex;
      flex-direction: column;
      /* border:1px solid black; */
      right:0px;
      top:50px;
      width:60%;
      height: -webkit-fill-available;
      /* padding:20px; */
      bottom:60px;
      
    background-color: var(----menu-background);

    }
    .mobileBurgerMenu {
      border:1px solid red;
    }
    .main-menu {
      padding-left: 10px;
      width:100%;
      padding-top: 20px;
      width:100vw;
    }
  }

 
  @media screen and (min-width:900px) {
    .reports {
      background-color: white;
    }
  }
    