.rapporter {
    padding:10px;
    width:100vw;
}
.custom-select-line {
    top:39px;
}
@media screen  and (min-width:900px){
    .rapport-mobil {
     /* border:1px solid red; */
     display:grid;
     grid-template-columns: 18% 82%;
     min-height: 954px;
    

    }
    .rapporter {
        width:100%;
        /* padding-top: 60px; */

        /* border:1px solid red */
    }
     .ugeaside{
        padding-top: 50px;
    }
    
}

