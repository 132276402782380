.workplanHeader{
/* border: 1px solid red; */
margin-top: 10px;
display:grid;
grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 1fr;
padding-left: 28px;
}

.workplanBody {
display:grid;
grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 1fr;
/* display:flex; */
/* justify-content: space-between; */
/* border-bottom:1px solid #dee0e3; */
padding-left:28px;
padding-top:8px;
position: relative;
/* background-color:antiquewhite ; */
}
.workplanBody:nth-child(odd) {
    background-color:  #f7f7f7
}
.indluk {
    border:1px solid green;
    width: 64px;
    height: 26px;
    margin-left:-10px;
    margin-top: 5px;
    /* padding-bottom: 20px; */
    box-sizing: border-box;
    padding-left: 10px;
    /* padding-top:-40px; */
    border-radius: 40px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #40b22d;
    color:white;
}

.noTime {
    padding-left:15px;
    width:30px;
    padding-top: 5px;
    /* margin-top: 8px; */
}

.udluk {
    
    width: 64px;
    height: 26px;
    padding-left: 10px;
    margin-top: 5px;
    margin-left: -15px;
    box-sizing: border-box;
    border-radius: 40px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #cf4747;
    color:white;
}

.workplanBody p {
    /* border:1px solid red */
}

@media screen and (max-width:1100px){
    .trunc {
        max-width:250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}