:root {
    color:#000;
    --font-color: #000;
    --background-color: #fff;
    --link-color: cornflowerblue;
  }
  
  [data-theme="dark"] {
    --font-color: #fff;
    /* color: #fff; */
    --background-color: #000;
    --link-color: lightblue;
   
  }

  
  .containers, .main-menu,  .table , .Calendar,.dagsCalendar, .dagsCalendar2  {
    background-color: var(--background-color) ;
    color: var(--font-color) ;
    height:auto
    /* color: rgb(31, 30, 30); */

  }
  
  a {
    color: var(--link-color);
  }
  
  /* Custom Dark Mode Toggle Element */
  
  .toggle-theme-wrapper {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* text-align: center; */
  }
  
  .toggle-theme-wrapper span {
    font-size: 28px;
    margin: 0 4px;
  }
  
  .toggle-theme {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
  }
  
  .toggle-theme input {
    display: none;
  }
  
  .slider {
    background-color: var(----navmenu-background);
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
  }
  
  .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  input:checked + .slider {
    background-color: darkgrey;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  