.login-page {
  display: grid;
  grid-template-columns: 18% 82%;
  font-family: lato;
  height:100%;
  min-height: 954px;
}


.login {
    /* border:1px solid lightgrey; */
    /* width:30%; */
    /* margin:auto; */
    /* padding: 50px; */
    width:260px;
    margin-left: 104px;
    margin-top: 57px;
    /* margin-top: 50px; */
    /* min-height:90vh; */

}
.login-title {

  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
   width: 84px;
  height: 20px; 
}
.login-desc{
  color:#101010;
  letter-spacing: 0.32px;
  /* width: 672px; */
  height: 20px;
}
.button {
    border:none;
    width:100%;
    height:40px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.56px;
    border-radius: 3px;
    padding:6px;
    background-color: var(----navmenu-background);
}

.error {
  color: #df3030;
  letter-spacing: 0.24px;
 font-size: 12px;
  /* font-weight: 600; */
}

@media screen and (max-width:500px){
  .login-page {
    display:flex;
    width: 100%;
/* border:1px solid red; */
  }
    .login {
      width:100%;
      padding:50px;
      margin-top: 0;
      /* align-items: center; */
      margin-left: 0;
    }
    .button {
      /* margin-top: 10px; */
    }
  }
  /* @media screen and (min-width:2000px){
    .login {
      width:60%;
      padding:50px;
    }
  } */