
.header-menu {
    font-family: lato;
  
    background-color: var(----primary-color);
    /* background-color: red; */
    height: 70px;
    display: flex;
    justify-content: space-between;
  }
  
  .menus {
    /* border: 1px solid white; */
    display: flex;
    width:400px;
    height:60px;
    margin-top: 5px;
    margin-left: -650px;
    /* justify-content: ; */
    /* line-height: 40px; */
  }
  .menu-item {
    box-sizing: border-box;
    text-align: center;
    /* margin: 10px 0px; */
    width:90px;
    border-radius: 15px;
margin: 5px 0px;
/* display:flex; */
/* flex-direction: column; */
/* border: 1px solid pink; */


  }
 .mobil-menu {
  display:none;
 }
  .menus-text {
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.14px;
  }
  .logInfo {
    /* border:1px solid white; */
    font-size: 16px;
    padding-top: 13px;
    height: 30px;
    padding-right: 13px;
    letter-spacing: 0.16px;
  }
  .user {
    padding-right: 10px;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: var(----user-color);
  }
  .user::first-letter {
    text-transform: capitalize;
  }
  .Rectangle-13 {
    width: 1px;
    height: 24px;
    margin-top:3px;
    flex-grow: 0;
    /* margin: 0 57px 0 46px; */
    background-color: #a83c3c;
  }
  .logout {
    background: transparent;
    outline: none;
    border: none;
    color: white;
    font-size: 16px;
    letter-spacing: 0.16px;
    /* border:2px solid white; */
    height: 24px;
    padding-left:10px;
    /* border-left: 1px solid var(----line-color); */
  }
  
  Link:hover {
  background-color: transparent;

  }
.burgerMenu {
  /* display:none */
}
.menu-item:hover {
  /* background-color: transparent; */
  background-color:transparent !important ;
}
a.active {
color:white;
background-color: var(----navmenu-background);

border-radius: 4px;

}
a {
  text-decoration: none;
}
a:hover {
  /* background-color: pink; */
}


@media screen and (max-width:900px){
  .menus {
    display:none;
    border:1px solid blue;

  }
   .user {
    display:none
   }
   .line {
    border:none
   }
   .header-menu {
    height:50px !important
   }
  
   .header-menu .logInfo {
    display:none
  }
  .mobil-menu {
    display:flex;
    justify-content: space-between;
    /* position: relative; */
    /* right: 0px; */
    /* top:0; */
    /* border:1px solid white; */
  }
  .mobil-menu .logud{
    background: transparent;
    color:white;
    border:none;
    outline:none;
    font-size: 15px;
    /* padding-top: 0px; */
    /* border:1px solid red */
  }

  /* burger menu style */
  .Rectangle-2 {
    position: relative;
    width: 50px;
    height: 43px;
    margin: 3px 3px 3px 5px;
    padding:12px;
    border-radius: 4px;
    background-color: var(----navmenu-background);
  }
  .Rectangle {
    width: 26px;
    height: 3px;
    margin: 0 0 4px;
    border-radius: 5px;
    background-color: #fff;
  }
  .burgerMenu {
    position:absolute;
    top:50px;
    right:0;
    z-index: 999;
    /* border:1px solid red; */
    width:70%;
    height:calc(100vh - 50px);
    background-color: var(----menu-background);
  }
  .burgerMenuItems {
    background-color: var(----primary-color);
    height: 42px;
    margin:3px;
    padding:7px;
    box-sizing: border-box;
    color:white;
    border-radius: 4px;
    display: flex;
  }
.arrow {
  border:none;
  background: transparent;
  position: absolute;
  right:10px;
  /* margin-left: 50%; */
}
.rotated {
  padding-top:5px;
  transform: rotate(-180deg);
}

}
@media screen and (min-width:900px) {
  .burgerMenu {
    width:0;
    display:none;
  }
  
}

@media screen and (max-width:900px) and (min-width:500px) {
  .burgerMenu {
    width:50%
  }
 }

@media screen and (max-width: 1440px) and (min-width: 901px) {
  .menus {
         margin-left: -300px
  }; 
  
 
 };

 

 /* @media screen and (max-width:400px){ */
  /* .header-menu {
    height: 50px;
  } */
  /* .logout  { */
    /* height:0px */
  
  /* .logInfo {
    display:none
  } */
  
