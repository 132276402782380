
.config-section {
    padding:70px
}
.themeBox {
    border:1px solid lightgrey;
    display:flex;
    justify-content: space-around;
    padding:20px 0;
    width:200px;
    border-radius: 5px;
    /* box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 12%); */
    /* background-color: #ececec; */
}
.pillarsConfig {
    /* border:1px solid black; */
    display:flex;
    justify-content: space-between;
    margin-top: 30px;
}
.configs {
    /* border:1px solid red; */
    width:300px;
}


@media screen and (max-width:900px) {
   
    .config-container {
    height:calc(100vh - 50px);

    }
}

@media screen and (max-width:1200px) {
.pillarsConfig {
    flex-direction: column;
}
.configs {
    margin-bottom: 30px;
}
}

@media screen and (min-width:900px) {
    .config-container {
        /* border:1px solid blue; */
        height:100%;
        display: grid;
        grid-template-columns: 18% 82%;
        min-height:954px;
        /* margin-bottom: 60px; */
    }
}